<template>

<app-page>

	<app-page-head :title="title" back="Categories">

		<app-page-head-action :disabled="!isDirty" icon="undo" tooltip="Undo changes" v-on:click.native="onUndoClick" />
		<app-page-head-action :loading="is.saving" :disabled="!isDirty" icon="save" tooltip="Save changes" v-on:click.native="onSaveClick" />
		<app-page-head-action route="Category.Delete" v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" tooltip="Delete" />
		
	</app-page-head>

	<app-page-content :is-grown="true">

		<app-page-content-section :is-padded="true" is-divided="!isNew" :grow="true">

			<app-input-text v-model="model.title" :disabled="model.id === 0" :validation="$v.model.title" label="Title" placeholder="Enter title" :maxlength="64" />

			<template v-if="model.id !== 0">
				<app-input-slug v-model="model.slug" :validation="$v.model.slug" :source="model.title" label="Slug" placeholder="Enter slug" :maxlength="64" />
				<app-input-banner v-model="model.banner" :validation="$v.model.banner" label="Banner" notes="Optimal image ratio is 5:2 and if responsive for desktops a minimum width of 1200px. Reduce file size by optimising first." />
				<app-input-text v-model="model.text" :toolbar="['bold', 'italic', 'underline', 'link']" :autogrow="true" :rich="true" label="Description" placeholder="Enter text" />
				<app-input-colour v-model="model.background" :validation="$v.model.background" label="Color" placeholder="Enter hex" />
				<app-input-icons v-model="model.icon" :validation="$v.model.icon" :bgcolor="model.background" label="Icon" :options="$constants.icons" />
				<app-input-toggle v-model="model.visible" label="Visible" />
				<app-input-toggle v-model="model.searchable" label="Searchable" />
				<app-input-tags v-model="model.terms" :dynamic-tag="model.title" label="Terms" />
			</template>

		</app-page-content-section>

		<app-page-content-link v-if="!isNew" is-divided="true" icon="groups" title="Groups" route="Category.Groups" :params="{id: model.id}" :count="groupCount" />

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, maxLength, minLength, alphaNum } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: false,
				slug: '',
				title: '',
				image: '',
				banner: {
					texts: [],
					image: '',
					type: 0
				},
				text: '',
				icon: '',
				background: '',
				visible: 1,
				searchable: 1,
				terms: []
			},
			param: 'category',
			noun: 'Category',
			store: 'categories'
		}

	},

	validations: {
		model: {
			title: {
				required,
				maxLength: maxLength(64)
			},
			slug: {
				required
			},
			background: {
				required,
				alphaNum,
				maxLength: maxLength(6),
				minLength: minLength(6)
			},
			icon: {
				required
			}
		}
	},

	computed: {

		groupCount: function() {

			return this.$store.getters['groups/find/category'](this.model.id).length

		}

	}

}

</script>

<style scoped>

</style>
